import { addEventDelegate } from "../premmio/public/modules/eventDelegate/eventDelegate.js";
import { isInViewport } from "../premmio/public/modules/isInViewport/isInViewport.js";
import * as nsfw from "../premmio/public/components/nsfw/nsfw.js";

nsfw.delegate();

const setScrolledValue = (scrollPos) => {
  const threshold = 100,
    header = document.querySelector("header");

  if (scrollPos > threshold) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
};

addEventDelegate("scroll", window, setScrolledValue);

const parallaxAsset = (scroll) => {
  const parallaxElements = document.querySelectorAll("img.asset.parallax");

  parallaxElements.forEach((element) => {
    // Calculate the element's distance from the top of the viewport
    const elementTopViewport = element.getBoundingClientRect().top;

    // Calculate the viewport's bottom position
    const viewportBottom = window.innerHeight;

    // Calculate how many pixels above the fold the top of the element is
    // Start counting as soon as the top of the element peeks above the fold
    let pixelsAboveFold = viewportBottom - elementTopViewport;

    // Ensure we only count positive values (element's top is at or above the fold)
    pixelsAboveFold = pixelsAboveFold > 0 ? pixelsAboveFold : 0;

    // Update the --parallax property with this value
    element.style.setProperty("--parallax", `${pixelsAboveFold}px`);
  });
};

addEventDelegate("scroll", window, parallaxAsset);

const enableParallax = (element) => {
  element.classList.add("parallax");
};

const disableParallax = (element) => {
  element.classList.remove("parallax");
};

isInViewport("img.asset", enableParallax, disableParallax);
